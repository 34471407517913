// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   table -> table
//   test -> richtext
//   markdown -> markdown
// end of automatic

import React from "react"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import BlockWrapper from "../technical/BlockWrapper"

import ReactMarkdown from "react-markdown"

const TableBlock = ({ block }) => {
  return (
    <BlockWrapper block={block}>
      {block.table !== "" && (
        <div class="prose prose-lg max-w-none mt-8 table-responsive overflow-x-auto">
          <table className="min-w-[768px] w-full">
            <thead>
              <tr>
                {block.table?.thead?.length > 0 &&
                  block.table.thead.map((thead, index) => (
                    <th>{thead.value}</th>
                  ))}
              </tr>
            </thead>
            <tbody>
              {block.table?.tbody?.length > 0 &&
                block.table.tbody.map((row, index) => (
                  <tr>
                    {row.body?.length > 0 &&
                      row.body.map((item, index) => <td>{item.value}</td>)}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <Richtext text={block.markdown} className="mt-8 table-responsive" />
    </BlockWrapper>
  )
}

export default TableBlock
